<template>
  <div class="padding-top-10" v-loading.fullscreen.lock="fullscreenLoading">
    <top :bgWhite="true"></top>
    <div class="content padding-bottom-60">
      <div class="flex align-center justify-between w60">
        <router-link
          :to="{
            path: '/talents_detail',
            query: { user_id: user && user.id ? user.id : 0 },
          }"
        >
          <div class="flex align-center writer-info">
            <div class="head-box">
              <el-image
                :src="user.head_img"
                class="head-img margin-right-xs radius-48 block-48"
                fit="cover"
              ></el-image>
              <img
                src="../../assets/image/icon/bigv.png"
                class="block-20 bigv"
                v-if="user.is_v == 1"
              />
            </div>
            <!-- <img :src="user.head_img" class="block-48 radius-48 margin-right-xs"> -->
            <div class="left-info">
              <p class="name text-black" style="color: #333">
                {{ user.user_nickname || "--" }}
              </p>
              <div
                class="writer-tag flex align-center margin-top-5"
                v-if="
                  user.tags_info &&
                  user.tags_info.length > 0 &&
                  user.tags_info[0] != ''
                "
              >
                <p
                  type="warning"
                  v-if="j < 2"
                  class="margin-right-5"
                  size="mini"
                  v-for="(ele, j) in user.tags_info"
                  :key="j"
                >
                  {{ ele }}
                </p>
              </div>
            </div>
          </div>
        </router-link>
        <div class="text-999">
          <span class="time margin-right-xs fs12">{{
            dataInfo.push_time
          }}</span>
          <el-dropdown
            trigger="click"
            v-if="
              dataInfo.user_id != (userInfo && userInfo.id ? userInfo.id : 0)
            "
          >
            <span class="el-dropdown-link">
              <i class="el-icon-more text-999"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="clearfix text-999 margin-bottom-20">
                <div class="flex align-center" @click="addFocus">
                  <img
                    src="../../assets/image/home/collect-gray.png"
                    class="block-18"
                  />
                  {{ dataInfo.is_focus == 1 ? "已关注" : "关注" }}
                </div>
              </el-dropdown-item>
              <el-dropdown-item class="clearfix text-999">
                <router-link :to="{ path: '/report', query: { aid: aid } }">
                  <div class="flex align-center">
                    <img
                      src="../../assets/image/home/report.png"
                      class="block-18 margin-right-5"
                    />
                    <span class="text-333">举报</span>
                  </div>
                </router-link>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="bs-sysMsg" v-if="dsList.length > 0">
        <div class="msg__content">
          <el-carousel
            height="30px"
            width="70%"
            direction="vertical"
            indicator-position="none"
            :autoplay="true"
          >
            <el-carousel-item v-for="(item, index) in dsList" :key="index">
              <img
                :src="item.head_img"
                style="
                  width: 18px;
                  height: 18px;
                  border-radius: 50%;
                  position: relative;
                  top: 5px;
                "
                alt=""
              />
              <span class="item" @click="dsdialogVisible = true"
                >{{ item.user_nickname }}打赏了该文章</span
              >
              <el-button
                type="danger"
                class="zmdbrn"
                size="small"
                @click="openAdmire"
                >去打赏</el-button
              >
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="w60 padding-top-10 fs14 text-666">
        <div class="flex align-center" v-if="atype == 2 || atype == 4">
          <div
            class="fs12 margin-right-10 representative_tag"
            v-if="dataInfo.is_representative == 1"
          >
            代表作
          </div>
          <!-- <div class="ftag fs12 margin-right-10 fee_tag" v-if="dataInfo.is_competition!=1 && dataInfo.is_free!=1" >
						{{dataInfo.is_free==1 ? '免费' : '收费'}}
					</div> -->
          <p class="bold fs16 margin-tb-20 text-333">{{ dataInfo.title }}</p>
        </div>
        <div style="width: 500px; height: 40px">
          <!-- <el-carousel height="40px" direction="vertical" :autoplay="false">
    <el-carousel-item v-for="item in 3" :key="item">	
      <h3 class="medium">{{ item }}</h3>
    </el-carousel-item>
  </el-carousel> -->
        </div>
        <p
          class="text-2em"
          style="word-break: break-all; line-height: 28px"
          v-html="setContent(dataInfo.body)"
          v-if="
            (atype == 2 || atype == 4) && (is_buy == 1 || dataInfo.is_free == 1)
          "
        ></p>
        <p
          class="text-2em text-cut-3"
          style="word-break: break-all"
          v-html="setContent2(dataInfo.body)"
          v-if="
            (atype == 2 || atype == 4) && is_buy == 0 && dataInfo.is_free == 0
          "
        ></p>
        <p
          class="text-2em"
          style="word-break: break-all"
          v-if="atype == 1"
          v-html="dataInfo.body"
        ></p>
        <!-- 收费内容 -->
        <div
          class="flex flex-direction align-center justify-around fee-box padding-tb-30 margin-top-20"
          v-if="
            dataInfo.is_free == 0 &&
            is_buy == 0 &&
            !fullscreenLoading &&
            atype != 1
          "
        >
          <p class="fs9 text-blue">
            抱歉哦！本文章为付费文章，需要购买后才能阅读…
          </p>
          <p class="fs9 text-red margin-tb-20">
            价格：￥{{ dataInfo.money || "0.00" }}
          </p>
          <el-button type="danger" round @click="toBuy">购买</el-button>
        </div>
        <el-row
          :gutter="24"
          v-if="dataInfo && dataInfo.images && dataInfo.is_video == 0"
          class="images_box"
        >
          <el-col
            :span="8"
            v-for="(img_item, img_index) in dataInfo.images"
            :key="img_index"
          >
            <div class="grid-content bg-purple">
              <el-image
                :src="img_item"
                fit="cover"
                class="w100 height148 radius-6"
                :preview-src-list="dataInfo.images"
              ></el-image>
            </div>
          </el-col>
        </el-row>
        <el-row
          :gutter="24"
          v-if="dataInfo && dataInfo.images && dataInfo.is_video == 1"
          class="margin-top"
        >
          <el-col
            :span="8"
            v-for="(img_item, img_index) in dataInfo.images"
            :key="img_index"
          >
            <video :src="img_item" controls="controls" width="400" height="">
              当前浏览器不支持 video直接播放，点击这里下载视频：
              <a :href="img_item">下载视频</a>
            </video>
          </el-col>
        </el-row>
      </div>
      <div class="w60">
        <div
          class="solid-bottom padding-tb-20 flex align-center justify-around"
        >
          <!-- <div class="point flex align-center" @click="toCollect(dataInfo)" :class="dataInfo.is_collect==1 ? 'text-theme' : 'text-999' ">
						<transition :name=" dataInfo.is_collect==1 ? 'zoom' : '' " mode="out-in">
							<img src="../../assets/image/icon/collect_fill.png" class="block-22 margin-right-5" v-if="dataInfo.is_collect==1" key="collect">
							<img src="../../assets/image/icon/collect.png" class="block-22 margin-right-5" v-else key="uncollect">
						</transition>
						<span class="fs14 ">{{dataInfo.is_collect==1 ? '取消收藏' :'收藏'}}</span>
					</div> -->
          <div class="point flex align-center">
            <el-popover placement="bottom" title="" width="200" trigger="hover">
              <div class="point">
                <div class="point flex align-center" @click="copyUrl">
                  <img
                    src="../../assets/image/icon/copy.png"
                    class="block-20 margin-right-10"
                  />
                  <span class="text-666">复制链接</span>
                </div>
                <div
                  class="margin-top flex align-center"
                  @click="transpond(dataInfo)"
                  v-if="dataInfo.ducoment_type != 0"
                >
                  <img
                    src="../../assets/image/icon/transpond.png"
                    class="block-24 margin-right-10"
                  />
                  <p class="text-666">转发</p>
                </div>
              </div>
              <div class="flex align-center" slot="reference">
                <img
                  src="../../assets/image/home/share.png"
                  class="block-22 margin-right-5"
                />
                <span class="fs14 text-999">{{
                  dataInfo.transmit_num || 0
                }}</span>
              </div>
            </el-popover>
          </div>
          <div class="point flex align-center">
            <img
              src="../../assets/image/home/read.png"
              class="block-22 margin-right-5"
            />
            <span class="fs14 text-999">{{ dataInfo.read_num || 0 }}</span>
          </div>
          <div class="point flex align-center" @click="giveLike(item)">
            <transition
              :name="dataInfo.is_like == 1 ? 'zoom' : ''"
              mode="out-in"
            >
              <img
                src="../../assets/image/home/zan1.png"
                class="block-22 margin-right-5"
                v-if="dataInfo.is_like == 1"
                key="like"
              />
              <img
                src="../../assets/image/home/zan.png"
                class="block-22 margin-right-5"
                v-else
                key="unlike"
              />
            </transition>
            <span
              class="fs14 text-999"
              :class="dataInfo.is_like == 1 ? 'text-theme' : 'text-999'"
              >{{ dataInfo.like_num || 0 }}</span
            >
          </div>
        </div>
        <div class="padding-10">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="contents"
            class="w100"
            rows="6"
            maxlength="500"
            show-word-limit
          >
          </el-input>
          <!-- <textarea rows="6" cols="" maxlength="500" class="bg-f6f7 w100 padding-20" v-model="contents"></textarea> -->
          <!-- <emoji-box @contentChange="contentChange" width="100%" height="120px"  :value="contents" ref="emojiPop"></emoji-box> -->
          <div class="flex align-center justify-end padding-top-15">
            <el-popover placement="right" width="400" trigger="click">
              <emotion
                @emotion="handleEmotion"
                :height="200"
                ref="emojiPop"
              ></emotion>
              <img
                slot="reference"
                src="../../assets/image/icon/smile.png"
                class="block-28 point"
              />
            </el-popover>
            <span
              class="text-white bg-e54f padding-lr-15 padding-tb-5 radius-6 margin-left-15 point"
              @click="postComment"
              >评论</span
            >
          </div>
        </div>
        <div class="padding-top-15">
          <div
            v-for="(item, index) in lists"
            :key="index"
            class="margin-bottom-22"
          >
            <evaluate-item
              :info="item"
              :show_star="false"
              :writer_id="dataInfo.user_id"
              :aid="aid"
              @refresh="refresh"
            ></evaluate-item>
          </div>
        </div>
      </div>
      <div class="flex align-center justify-center">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="totalSize"
          :page-size="15"
          :hide-on-single-page="true"
          @current-change="currentChange"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 悬浮按钮 -->
    <div class="float-box flex flex-direction align-center justify-around">
      <div
        class="flex align-center justify-center radius-100"
        style="margin-bottom: -20px"
        v-if="atype == 2"
      >
        <img
          src="../../assets/image/home/text.png"
          class="block-60"
          @click="openAdmire"
        />
      </div>
      <div
        class="img-box flex align-center justify-center"
        v-show="scrollTop > 500"
      >
        <img
          src="../../assets/image/home/top.png"
          class="block-19"
          @click="scrollToTop"
        />
      </div>
    </div>
    <!-- 赞赏弹窗 -->
    <el-dialog
      :title="'赏' + user.user_nickname"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div class="">
        <el-row :gutter="24" v-loading="loading">
          <el-col
            :span="6"
            v-for="(ele, j) in awardLists"
            :key="j"
            class="margin-bottom-10 point"
          >
            <div
              class="tabItem flex align-center justify-center"
              :class="awardTab == j ? 'selected' : 'normal'"
              @click="tabSelect(ele, j)"
            >
              <span class="text-666 point fs9">{{ ele.money }}元</span>
            </div>
          </el-col>
        </el-row>
        <div class="flex align-center margin-top-20">
          <el-row :gutter="24" class="flex align-center">
            <el-col :span="14">
              <el-input
                placeholder="自定义(元)"
                type="number"
                v-model="reward"
                @input="numContro($event, 'claimMessageVo', 'checkfee')"
              ></el-input>
            </el-col>
            <span>元</span>
            <!-- <el-col :span="10">
							<el-input-number v-model="num" :step="2" size="mini"></el-input-number>
						</el-col> -->
          </el-row>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="admire">赏</el-button>
      </span>
    </el-dialog>
    <!-- 打赏列表弹窗 -->
    <el-dialog
      title="全部打赏"
      center
      :visible.sync="dsdialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div>
        <div
          class="flex justify-between"
          v-for="(citem, index) in dsList"
          :key="index"
        >
          <div>
            <img
              :src="citem.head_img"
              style="
                width: 18px;
                height: 18px;
                border-radius: 50%;
                position: relative;
                top: 5px;
              "
              alt=""
            />
            {{ citem.user_nickname }}打赏了该文章
          </div>
          <div>
            <el-button type="danger" size="small" @click="openAdmire"
              >去打赏</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
    <Modal
      :show="successModal"
      @hideModal="successModal = false"
      @submit="successModal = false"
    >
      <div
        class="modal-box flex flex-direction align-center justify-end padding-bottom-10"
      >
        <div class="flex flex-direction align-center">
          <p class="fs16">打赏成功！</p>
          <div class="flex align-center">
            <p class="fs9">感谢您对创作者的支持！</p>
            <img src="../../assets/image/flower.png" class="block-16" />
          </div>
        </div>
      </div>
    </Modal>
    <bottom :bgWhite="true"></bottom>
  </div>
</template>

<script>
import Modal from "@/components/pop-up.vue";
import evaluateItem from "@/components/evaluate/evalaute.vue";
import top from "@/components/top";
import bottom from "@/components/bottom";
import connect from "../../eventBus.js";
import Emotion from "@/components/Emotion/index";
export default {
  name: "artileDetail",
  components: {
    bottom,
    top,
    evaluateItem,
    Modal,
    Emotion,
  },
  data() {
    return {
      fullscreenLoading: true,
      baseUrl: this.imgUrl,
      loading: false,
      showReward: true, //是否显示赞赏列表
      reward: "", //赞赏金额
      num: 0, //
      page: 1, //当前页数
      aid: "", //
      content: "",
      contents: "", //富文本内容
      editorOption: {},
      imageUrl: "", //封面
      scrollTop: 0,
      lists: [], //评论列表
      user: {}, //作者信息
      dataInfo: {}, //详情信息
      status: "more",
      totalSize: 0,
      dialogVisible: false,
      dsdialogVisible: false,
      successModal: false,
      awardTab: 0, //赞赏金额索引
      awardLists: [],
      is_click: false,
      atype: 1, //文章类型
      is_buy: 0, //是否购买
      dsList: [], //打赏列表
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  mounted() {
    // todo
    this.aid = this.$route.query.article_id;
    this.atype = this.$route.query.atype;
    // this.aid = 9;
    window.addEventListener("scroll", this.handleScroll);
    this.getArticleDetail(); //获取文章详情
    this.getCommentLists(); //获取评论列表
    this.loaduserData(); // 获取打赏列表
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    setContent(res) {
      const regex = new RegExp("<img", "gi");
      return res.replace(
        regex,
        `<img style="max-width: 80%; height: auto;margin:1rem 0;display:block"`
      );
    },
    setContent2(res) {
      const regex = new RegExp("<img", "gi");
      return res.replace(regex, `<img style="display:none"`);
    },
    // 转发
    transpond(item) {
      this.$router.push({
        path: "/transpond",
        query: {
          ducoment_type: item.ducoment_type,
          aid: item.aid,
        },
      });
    },
    // 购买作品
    toBuy() {
      this.ajax(
        "post",
        "/v1/5d784b976769e",
        {
          order_type: 6, //1 ：'现金充值'，2：'虚拟币充值'，3 ：'商城交易'，4：认证支付5：打赏6：文章购买
          document_id: this.aid,
        },
        (res) => {
          if (res.code == 1) {
            this.$router.push({
              path: "/payMoney",
              query: {
                order_sn: res.data.order_sn,
                order_money: res.data.money,
                return_url: JSON.stringify(window.location.href),
              },
            });
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },
    // 打赏列表加载
    loaduserData() {
      this.ajax(
        "post",
        "/v1/635b871ea2027",
        {
          document_id: this.aid, //文章id
        },
        (res) => {
          this.isInit = true;
          if (res.code == 1) {
            this.dsList = res.data.list;
            // 						this.dsList.push({
            //     "aid": 9069,
            //     "real_money": "1.00",
            //     "user_id": 1925,
            //     "order_sn": "DS20221201092332786366",
            //     "document_id": 6466,
            //     "user_name": "sajdhf",
            //     "user_nickname": "hsadg",
            //     "head_img": "https://xiangcunrc.magic-house.cn/uploads/images/c4/045a4c32f8d418d238ebf83f01f945.png"
            // })
          } else {
            this.$message.error(res.msg);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    },
    // 复制链接
    copyUrl() {
      let invitelink = `${this.baseUrl}/#/artileDetail?article_id=${this.aid}&atype=${this.atype}`;
      this.$copyText(invitelink).then(
        (res) => {
          this.$message.success("成功复制到剪贴板");
        },
        (err) => {
          console.log(err); // 同上
        }
      );
    },
    // 赞赏金额输入框
    //控制金额的位数
    numContro(value, box, name) {
      let val = (value && value.split("")) || [];
      let sNum = val.toString(); //先转换成字符串类型
      if (sNum.indexOf(".") == 0) {
        //第一位就是 .
        console.log("first str is .");
        sNum = "0" + sNum;
      }
      sNum = sNum.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
      sNum = sNum.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      sNum = sNum.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      sNum = sNum.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
      if (sNum.indexOf(".") < 0 && sNum != "") {
        sNum = parseFloat(sNum);
      }
      this.reward = sNum;
    },
    // 打开表情弹窗
    showEmoji() {
      this.$refs.emojiPop.showHide();
    },
    contentChange(e) {
      console.log(e);
      this.contents = e.value;
    },
    // 获取打赏列表
    getRewardLists() {
      this.loading = true;
      this.ajax("post", "/v1/616d0b30a5152", {}, (res) => {
        this.loading = false;
        if (res.code == 1) {
          this.awardLists = res.data;
          this.reward = res.data[0].money;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 点赞
    giveLike() {
      this.ajax(
        "post",
        "/v1/61419389e89d5",
        {
          article_id: this.dataInfo.aid,
        },
        (res) => {
          if (res.code == 1) {
            this.dataInfo.is_like = this.dataInfo.is_like == 1 ? 0 : 1;
            window.opener.getSonData();
            if (this.dataInfo.is_like == 1) {
              this.dataInfo.like_num += 1;
            } else {
              this.dataInfo.like_num -= 1;
            }
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },
    // 选择赞赏金额
    tabSelect(item, index) {
      if (this.awardTab == index) return;
      this.awardTab = index;
      this.reward = Number(item.money);
    },
    // 关闭弹窗前
    handleClose() {
      this.dialogVisible = false;
      this.dsdialogVisible = false;
    },
    // 打开赞赏弹窗
    openAdmire() {
      this.getRewardLists(); //获取打赏列表
      this.dialogVisible = true;
    },
    // 赞赏
    admire() {
      this.dialogVisible = false;
      // this.successModal = true;
      this.addOrder();
    },
    // 统一下单接口
    addOrder() {
      this.ajax(
        "post",
        "/v1/5d784b976769e",
        {
          order_type: 5,
          document_id: this.dataInfo.aid,
          order_money: this.reward,
        },
        (res) => {
          if (res.code == 1) {
            this.$router.push({
              path: "/payMoney",
              query: {
                order_sn: res.data.order_sn,
                order_money: res.data.money,
                return_url: JSON.stringify(window.location.href),
              },
            });
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },
    // 收藏
    toCollect() {
      this.ajax(
        "post",
        "/v1/6141a0f6bd7d0",
        {
          article_id: this.dataInfo.aid,
        },
        (res) => {
          if (res.code == 1) {
            window.opener.getSonData();
            this.dataInfo.is_collect = this.dataInfo.is_collect == 1 ? 0 : 1;
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },
    // 点击当前页
    currentChange(e) {
      this.page = e;
      this.getCommentLists();
    },
    // 加关注
    addFocus() {
      this.ajax(
        "post",
        "/v1/61419cb4727bd",
        {
          fuid: this.dataInfo.user_id,
        },
        (res) => {
          if (res.code == 1) {
            window.opener.getSonData();
            this.dataInfo.is_focus = this.dataInfo.is_focus == 1 ? 0 : 1;
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },
    // 刷新评论列表、
    refresh(e) {
      this.status = "more";
      this.getCommentLists();
    },
    handleScroll(e) {
      this.scrollTop =
        e.target.documentElement.scrollTop || e.target.body.scrollTop; // 执行代码
    },
    // 获取文章详情
    getArticleDetail() {
      this.ajax(
        "post",
        "/v1/613afc809f5e3",
        {
          article_id: this.aid,
        },
        (res) => {
          this.fullscreenLoading = false;
          if (res.code == 1) {
            this.user = res.data.user;
            document.title = res.data.title ? res.data.title : "动态详情";
            this.dataInfo = res.data;
            this.is_buy = res.data.is_buy;
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },
    // 提交评论
    postComment() {
      if (!this.contents.trim()) {
        this.$message.error("请先输入评论内容");
        return false;
      }
      if (this.is_click) {
        this.$message.info("您操作的太频繁啦");
        return;
      }
      this.is_click = true;
      setTimeout(() => {
        this.is_click = false;
      }, 1500);
      this.ajax(
        "post",
        "/v1/6151764c066ea",
        {
          document_id: this.aid,
          content: this.contents,
          // id:'' //回复谁传谁的id
        },
        (res) => {
          if (res.code == 1) {
            window.opener.getSonData();
            this.$message.success("评论成功");
            this.getArticleDetail();
            this.contents = "";
            this.status = "more";
            this.getCommentLists();
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },
    // 获取评论列表
    getCommentLists() {
      if (this.status != "more") return;
      this.ajax(
        "post",
        "/v1/61516d9c4dc60",
        {
          page: this.page,
          document_id: this.aid,
          id: "", //回复谁传谁的id
        },
        (res) => {
          this.isInit = true;
          if (res.code == 1) {
            this.totalSize = res.data.total;
            this.lists = res.data.data.map((item) => {
              item.is_open = false;
              return item;
            });
            if (this.page < res.data.last_page) {
              this.status = "more";
              this.page++;
            } else {
              this.status = "noMore";
            }
          } else {
            this.$message.error(res.msg);
          }
        },
        (err) => {
          console.log(err);
          this.hadGotCode = false;
        }
      );
    },
    // 显示按钮的显示与隐藏
    scrollToTop() {
      let scrollToptimer = setInterval(function () {
        // console.log("定时循环回到顶部");
        var top = document.body.scrollTop || document.documentElement.scrollTop;
        var speed = top / 4;
        document.documentElement.scrollTop -= speed;
        if (top == 0) {
          clearInterval(scrollToptimer);
        }
      }, 30);
    },
    // 上传成功回调
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    // 上传封面
    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt10M = file.size / 1024 / 1024 < 2;

      // if (!isJPG) {
      // 	this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt10M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isLt10M;
    },
    submitForm() {
      this.$router.push({
        path: "/memberDate",
      });
      console.log(this.$refs.text.value);
    },
    handleEmotion(i) {
      this.contents += i;
    },
    // 将匹配结果替换表情图片
    emotion(res) {
      let word = res.replace(/\#|\;/gi, "");
      const list = [
        "微笑",
        "撇嘴",
        "色",
        "发呆",
        "得意",
        "流泪",
        "害羞",
        "闭嘴",
        "睡",
        "大哭",
        "尴尬",
        "发怒",
        "调皮",
        "呲牙",
        "惊讶",
        "难过",
        "酷",
        "冷汗",
        "抓狂",
        "吐",
        "偷笑",
        "可爱",
        "白眼",
        "傲慢",
        "饥饿",
        "困",
        "惊恐",
        "流汗",
        "憨笑",
        "大兵",
        "奋斗",
        "咒骂",
        "疑问",
        "嘘",
        "晕",
        "折磨",
        "衰",
        "骷髅",
        "敲打",
        "再见",
        "擦汗",
        "抠鼻",
        "鼓掌",
        "糗大了",
        "坏笑",
        "左哼哼",
        "右哼哼",
        "哈欠",
        "鄙视",
        "委屈",
        "快哭了",
        "阴险",
        "亲亲",
        "吓",
        "可怜",
        "菜刀",
        "西瓜",
        "啤酒",
        "篮球",
        "乒乓",
        "咖啡",
        "饭",
        "猪头",
        "玫瑰",
        "凋谢",
        "示爱",
        "爱心",
        "心碎",
        "蛋糕",
        "闪电",
        "炸弹",
        "刀",
        "足球",
        "瓢虫",
        "便便",
        "月亮",
        "太阳",
        "礼物",
        "拥抱",
        "强",
        "弱",
        "握手",
        "胜利",
        "抱拳",
        "勾引",
        "拳头",
        "差劲",
        "爱你",
        "NO",
        "OK",
        "爱情",
        "飞吻",
        "跳跳",
        "发抖",
        "怄火",
        "转圈",
        "磕头",
        "回头",
        "跳绳",
        "挥手",
        "激动",
        "街舞",
        "献吻",
        "左太极",
        "右太极",
      ];
      let index = list.indexOf(word);
      return `<img src="https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${index}.gif" align="middle">`;
    },
  },
};
</script>

<style  lang="scss">
html,
body {
  background: #ffffff !important;
}
.el-textarea__inner {
  // background: none;
  // border: none;
}
.head-box {
  position: relative;
  .bigv {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
}
.fee-box {
  width: 100%;
  background: rgba(62, 98, 177, 0.04);
}
.edit-contain {
  width: 100vw;
  height: 100vh;
  background-color: #f8f8f8;
  overflow: auto;
}
.representative_tag {
  min-width: 46px;
  text-align: center;
  height: 20px;
  line-height: 20px;
  border-radius: 0 8px;
  font-weight: bold;
  color: #e54f42;
  background: #fdedeb;
  padding: 2px;
}
.ftag {
  width: 36px;
  text-align: center;
  height: 20px;
  line-height: 20px;
  border-radius: 0 8px;
  font-weight: bold;
}

.free_tag {
  color: #31cba4;
  background: #caf4d7;
}

.fee_tag {
  color: #ff7f37;
  background: #fdedeb;
}
.content {
  width: 80%;
  height: auto;
  margin: 60px auto;
}
.images_box {
  margin-top: 20px;
}

.float-box {
  position: fixed;
  right: 120px;
  bottom: 138px;
  width: 48px;
  height: 164px;
  opacity: 1;
  border-radius: 43px;

  .img-box {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.08);
  }
}
.tabItem {
  width: 80px;
  height: 32px;
  opacity: 1;
  border-radius: 5px;
}

.normal {
  background: #f6f7f9;
  border: 1px solid #f6f7f9;
  color: #666666;
}

.selected {
  background: rgba(229, 79, 66, 0.08);
  border: 1px solid #ea5046;
  color: #e54f42;
}

.dialog-footer {
  button {
    width: 100%;
    border-radius: 29px;
  }
}
.modal-box {
  width: 194px;
  height: 190px;
  background: url(../../assets/image/reward-bg.png) no-repeat;
  background-size: 100% 100%;
}
/** 动画进行时的class **/
.zoom-enter-active,
.zoom-leave-active {
  transition: all 0.15s cubic-bezier(0.42, 0, 0.34, 1.55);
}

/** 设置进场开始的状态和离场结束的状态，都是缩放到0 **/
.zoom-enter,
.zoom-leave-to {
  transform: scale(0);
}

/** 设置进场结束的状态和离场开始的状态, 都是缩放到1 **/
.zoom-enter-to,
.zoom-leave {
  transform: scale(1);
}
.writer-tag {
  p {
    width: 66px;
    height: 20px;
    text-align: center;
    opacity: 1;
    font-size: 8px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 20px;
    color: #ffffff;
    opacity: 1;
    &:nth-child(1) {
      background: url(../../assets/image/icon/label-bg1.png) no-repeat;
      background-size: cover;
    }
    &:nth-child(2) {
      background: url(../../assets/image/icon/label-bg2.png) no-repeat;
      background-size: cover;
    }
  }
}
.bs-sysMsg {
  position: relative;
  display: flex;
  width: 22%;
  padding: 0 12px;
  height: 40px;
  margin-top: 10px;
  margin-left: 20%;
  border-radius: 20px;
  background-color: #eee;
  overflow: hidden;
  opacity: 1;
  align-items: center;
  transition: opacity 0.2s;
}
.bs-sysMsg .msg__content {
  display: table-cell;
  padding: 0 8px;
  width: 100%;
}
.zmdbrn {
  margin-left: 70%;
  border-radius: 10px;
  height: 30px;
  position: relative;
  top: -22px;
  z-index: 99;
}
.bs-sysMsg .msg__content .item {
  color: #333;
  display: inline-block;
  position: relative;
  left: 5px;
  top: 3px;
  font-size: 14px;
  cursor: pointer;
}
.bs-sysMsg .msg__content a.item:hover {
  text-decoration: underline;
}
</style>
